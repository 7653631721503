// Generated by Framer (a7ecdf4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./TO3zyIAFs-0.js";

const cycleOrder = ["S0aB_BQDg", "boPFpQSGw"];

const serializationHash = "framer-yJIdr"

const variantClassNames = {boPFpQSGw: "framer-v-kzeqwt", S0aB_BQDg: "framer-v-o47kl8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {ZrCZmM6xT: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Black Logo": "boPFpQSGw"}

const getProps = ({height, id, link, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "S0aB_BQDg", zGOOudHsX: link ?? props.zGOOudHsX} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, zGOOudHsX, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "S0aB_BQDg", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><Link href={zGOOudHsX}><Image {...restProps} animate={variants} as={"a"} background={{alt: "", intrinsicHeight: 500, intrinsicWidth: 500, pixelHeight: 500, pixelWidth: 500, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/DT8sT6wn4W36Docws04l7fYP7Y.png"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-o47kl8", className, classNames)} framer-1ncz5wf`} data-framer-name={"Black Logo"} initial={variant} layoutDependency={layoutDependency} layoutId={"S0aB_BQDg"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({boPFpQSGw: {background: {alt: "", intrinsicHeight: 500, intrinsicWidth: 500, pixelHeight: 500, pixelWidth: 500, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/Go0eNImNBQEg9YELWgN70JM0MMI.svg"}}}, baseVariant, gestureVariant)}/></Link></Transition>
</LayoutGroup>)

});

const css = [".framer-yJIdr[data-border=\"true\"]::after, .framer-yJIdr [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yJIdr.framer-1ncz5wf, .framer-yJIdr .framer-1ncz5wf { display: block; }", ".framer-yJIdr.framer-o47kl8 { height: 32px; overflow: visible; position: relative; text-decoration: none; width: 119px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 119
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"boPFpQSGw":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"zGOOudHsX":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerTO3zyIAFs: React.ComponentType<Props> = withCSS(Component, css, "framer-yJIdr") as typeof Component;
export default FramerTO3zyIAFs;

FramerTO3zyIAFs.displayName = "Logo";

FramerTO3zyIAFs.defaultProps = {height: 32, width: 119};

addPropertyControls(FramerTO3zyIAFs, {variant: {options: ["S0aB_BQDg", "boPFpQSGw"], optionTitles: ["Black Logo", "Black Logo"], title: "Variant", type: ControlType.Enum}, zGOOudHsX: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerTO3zyIAFs, [])